<template>
  <base-card class="card-hover" @click.native.prevent="$emit('selected')">
    <v-card-text class="d-flex flex-column flex-md-row justify-space-between align-center flex-wrap mb-4">
      <div class="d-flex align-center">
        <v-img class="mr-md rounded mr-8" height="70" width="70" gradient="to bottom, rgba(0,0,0,.05), rgba(0,0,0,.05)"
          :src="getProductPrimaryImage(offer.offer.product)" />
        <div>
          <p class="mb-2">
            <a href="#" class="text--primary h5 font-weight-medium mb-1">
              {{ offer.offer.product.name }} ({{ isGroupOffer? 'Group Offer': 'Exclusive Offer' }} <span
                v-if="offer.offer.expires_at" :key="timeAgoRefreshKey">{{ expiresAtLabel }}</span>)
            </a>
          </p>
          <p class="caption mb-2" v-if="offer.offer.product.purchase_price"><b>Purchase Price:</b> {{
            formatCostInPence(offer.offer.product.purchase_price, offer.offer.product.currency)
          }}</p>
          <p class="caption ma-0" v-if="offer.offer.product.expected_sale_price"><b>Expected Sale Price:</b> {{
            formatCostInPence(offer.offer.product.expected_sale_price, offer.offer.product.currency)
          }}</p>
        </div>
      </div>
      <div class="view-wrapper">
        <v-btn @click.prevent="$emit('selected')" class="ma-2" depressed> View</v-btn>
      </div>
    </v-card-text>
  </base-card>
</template>

<script>
import TimeAgoRefresher from "../../mixins/TimeAgoRefresher";
import Products from "../../mixins/Products";
import Dates from "../../mixins/Dates";
import Display from "../../mixins/Display";
export default {
  props: ['offer'],
  mixins: [TimeAgoRefresher, Dates, Display, Products],
  computed: {
    isGroupOffer() {
      return this.offer.offer.is_group_offer;
    },

    expiresAtLabel() {
      return '- Expires ' + this.displayTimeAgo(this.offer.offer.expires_at);
    }
  }
};
</script>

<style scoped lang="scss">
.card-hover {
  cursor: pointer;
}

@media screen and (max-width: 960px) {
  .view-wrapper {
    margin-top: 20px;
    width: 100%;

    .v-btn {
      width: 100%;
      height: 40px;
    }
  }
}
</style>