<template>
  <div class="admin-dashboard">
    <v-container fluid>
      <div class="welcome">
        <h1>Welcome Back<span v-if="user">, {{ user.name  }}</span></h1>
      </div>

      <!-- Do some loading thing here -->
      <div class="loading" v-if="isLoading">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <!-- / Do some loading thing here -->

      <!-- Loaded -->
      <div v-if="!isLoading && dashboard">
        <div class="mb-4">
          <h3>Stock Offered To Consignors</h3>
        </div>

        <v-alert v-if="!isLoading && offers.length == 0" type="info"
          >Nothing to show here!</v-alert
        >

        <admin-offer-card
          :offer="{ offer: offer }"
          v-for="offer in offers"
          :key="offer.id"
          @selected="viewProduct(offer.product)"
        ></admin-offer-card>

        <div class="mb-4 pt-4">
          <h3>Untrusted Consignor Invites That Need Approving</h3>
          <approvals-table></approvals-table>
        </div>

        <div class="mb-4 pt-4">
          <h3>Pending Invoices (Due To MAIA From Consignor For Bag Purchase)</h3>
          <pending-invoices-table></pending-invoices-table>
        </div>
      </div>
      <!-- / Loaded -->
    </v-container>
  </div>
</template>


<script>
import admin from "../../../api/admin";
import AdminOfferCard from "../../../components/offers/AdminOfferCard.vue";
import ApprovalsTable from "../../../components/investors/ApprovalsTable.vue";
import PendingInvoicesTable from "../../../components/invoices/PendingInvoicesTable.vue";
import {mapGetters} from "vuex";
import jsPDF from 'jspdf'

export default {
  name: "Dashboard",

  mixins: [],

  components: { AdminOfferCard, ApprovalsTable, PendingInvoicesTable },

  data() {
    return {
      isLoading: false,
      dashboard: null,
    };
  },

  created() {
    this.load();
  },

  computed: {
    ...mapGetters('auth', {
      user: 'user'
    }),

    offers() {
      if (this.dashboard && this.dashboard.offers) {
        return this.dashboard.offers;
      }
      return [];
    },
  },

  methods: {
    viewProduct(product) {
      this.$router.push("/products/" + product.id);
    },

    load() {

      let vm = this;
      vm.isLoading = true;
      admin
        .getDashboard()
        .then((r) => {
          vm.dashboard = r.data;
          vm.isLoading = false;
        })
        .catch((e) => {
          console.log(e);
          vm.$toast.error("Error loading dashboard");
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.loading {
  text-align: center;
  height: 100%;
  padding-top: 100px;
}

.admin-dashboard {
  padding: 15px;

  .welcome {
    margin-bottom: 20px;
  }

  h3 {
    font-size: 1.35rem;
    margin-bottom: 15px;
  }
}
</style>
