<template>
    <div class="approvals-table">
        <!-- <v-alert v-if="totalInvestors == 0" type="info"
          >Nothing to show here!</v-alert
        > -->

        <v-data-table :headers="headers" :items="investors" :options.sync="options" 
            :server-items-length="totalInvestors" :loading="loading" class="elevation-1">
            <template v-slot:item.actions="{ item }">
                <v-btn small depressed @click.native.prevent="$router.push('/approvals/' + item.id)">
                    <v-icon small class="me-2">mdi-eye</v-icon>
                    View
                </v-btn>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import investors from "../../api/investors";
export default {
    data() {
        return {
            totalInvestors: 0,
            investors: [],
            loading: true,
            options: {},
            headers: [
                {
                    text: "Name",
                    align: "start",
                    value: "name",
                },
                { text: "Email", value: "email" },
                { text: "Phone Number", value: "phone_number" },
                { text: "Actions", value: "actions", sortable: false, width: "100px" },
            ],
        };
    },
    watch: {
        options: {
            handler() {
                this.fetch();
            },
            deep: true,
        },

        searchQuery() {
            this.fetch();
        }
    },
    methods: {
        getFormParams() {
            let params = {};

            const { sortBy, sortDesc, page, itemsPerPage } = this.options;

            params.resultsPerPage = itemsPerPage;
            params.page = page;
            if (sortBy.length) {
                params.sortField = _.first(sortBy);
            }
            if (sortDesc.length) {
                let isDescending = _.first(sortDesc);
                params.sortOrder = isDescending ? "descend" : "ascend";
            }

            params.account_setup = true;
            params.id_verified = true;
            params.approved = false;

            if (this.searchQuery && this.searchQuery.trim().length) {
                params.q = this.searchQuery;
            }

            return params;
        },

        fetch() {
            this.loading = true;
            investors.search(this.getFormParams()).then((r) => {
                this.investors = r.data.data;
                this.totalInvestors = r.data.total;
                this.loading = false;
            });
        },
    },
};
</script>

<style lang="scss">
.approvals-table {
    .toolbar {
        margin-bottom: 10px;
        display: flex;

        .left {
            flex-grow: 1;
            display: flex;
        }

        .right {
            flex-shrink: 1;
        }

        .toolbar-option {
            max-width: 300px;
            margin-right: 30px;
        }
    }

    tbody {
        tr:hover {
            background-color: transparent !important;
        }
    }
}
</style>