<template>
    <div class="pending-invoices-table">
        <v-data-table :headers="headers" :items="invoices" :options.sync="options" :server-items-length="totalInvoices"
            :loading="loading" v-model="selected" class="elevation-1">
            <template v-slot:item.total="{ item }">
                {{ formatCostInPence2dp(item.total, item.currency) }}
            </template>
            <template v-slot:item.paid="{ item }">
                <v-icon class="success--text" v-if="item.paid">mdi-check</v-icon>
                <v-icon class="danger--text" v-else>mdi-window-close</v-icon>
            </template>
            <template v-slot:item.actions="{ item }">
                <div>
                    <v-btn small depressed class="mr-3"
                        @click.native.prevent="() => viewProduct(item)">
                        <v-icon small class="me-2">mdi-eye</v-icon>
                        View</v-btn>

                    <v-btn v-if="!item.paid" small depressed
                        @click.native.prevent="() => attemptMarkInvoiceAsPaid(item)">
                        <v-icon small class="me-2">mdi-check</v-icon>
                        Mark As Paid</v-btn>
                </div>
            </template>
        </v-data-table>

        <!-- Mark as paid modal -->
        <v-dialog v-model="showMarkAsPaidModal" max-width="400">
            <v-card>
                <v-card-title class="text-h5 mb-4"> Please Confirm </v-card-title>

                <v-card-text>
                    Are you sure you want to mark this invoice as paid?
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="red darken-1" text @click="(attemptingToMarkInvoiceAsPaid = null)">
                        Cancel
                    </v-btn>

                    <v-btn color="primary darken-1" text @click="confirmMarkAsPaid">
                        Confirm
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!-- / Mark as paid modal -->
    </div>
</template>

<script>
const _ = require("lodash");
import invoices from "../../api/invoices";
import Display from "../../mixins/Display";
import Forms from "../../mixins/Forms";
export default {
    mixins: [Display, Forms],
    data() {
        return {
            totalInvoices: 0,
            invoices: [],
            loading: true,
            options: {},
            selected: [],
            headers: [
                {
                    text: "Description",
                    align: "start",
                    value: "description",
                },
                {
                    text: "Consignor",
                    value: "account.user.name"
                },
                {
                    text: "Total",
                    value: "total"
                },
                {
                    text: "Paid",
                    value: "paid"
                },
                { text: "Actions", value: "actions", sortable: false, width: "280px" },
                // {
                // text: "Balance",
                // value: "balance",
                // },
            ],
            isSaving: false,

            attemptingToMarkInvoiceAsPaid: null
        };
    },
    watch: {
        options: {
            handler() {
                this.search();
            },
            deep: true,
        },
    },
    computed: {
        showMarkAsPaidModal: {
            get() {
                return this.attemptingToMarkInvoiceAsPaid !== null;
            },
            set(val) {
                if (!val) {
                    this.attemptMarkInvoiceAsPaid = null;
                }
            }
        }
    },
    methods: {
        viewProduct(item) {
            this.$router.push('/products/' + item.product_id);
        },

        attemptMarkInvoiceAsPaid(invoice) {
            this.attemptingToMarkInvoiceAsPaid = invoice;
        },

        confirmMarkAsPaid() {
            let vm = this;
            vm.isSaving = true;
            invoices.markAsPaid(this.attemptingToMarkInvoiceAsPaid.id).then(r => {
                vm.attemptingToMarkInvoiceAsPaid = null;
                vm.$toast.success('Invoice marked as paid successfully');
                vm.search();
            }).catch(e => {
                console.log(e);
                let errors = vm.getErrorsAsArray(e);
                let error;
                if (errors.length) {
                    error = _.first(errors);
                } else {
                    error = "Error marking invoice as paid";
                }
                vm.$toast.error(error);
                vm.attemptingToMarkInvoiceAsPaid = null;
            });
        },

        getFormParams() {
            let params = {
                paid: false
            };

            const { sortBy, sortDesc, page, itemsPerPage } = this.options;

            params.resultsPerPage = itemsPerPage;
            params.page = page;
            if (sortBy.length) {
                params.sortField = _.first(sortBy);
            }
            if (sortDesc.length) {
                let isDescending = _.first(sortDesc);
                params.sortOrder = isDescending ? "descend" : "ascend";
            }

            return params;
        },

        search() {
            this.loading = true;
            invoices
                .search(this.getFormParams())
                .then((r) => {
                    this.invoices = r.data.data;
                    this.totalInvoices = r.data.total;
                    this.loading = false;
                });
        }
    },
};
</script>

<style lang="scss">
.pending-invoices-table {
    tbody {
        tr:hover {
            background-color: transparent !important;
        }
    }
}

//   .expires-at-b-label {
//     display: block;
//     margin-bottom: 10px;
//   }
</style>
